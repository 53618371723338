// import React from 'react';
// import bannerImage from '../img/banner/banner.jpg'; // Adjust the path according to your project structure

// const MainBanner = () => {
//   return (
//     <div className="hero-area overly-style-1 opacity-point-4" style={{ height: '650px', position: 'relative' }}>
//       {/* Image that covers the full screen */}
//       <img 
//         className="banner-image" 
//         src={bannerImage} 
//         alt="Main Banner" 
//         style={{ 
//           // position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           objectFit: 'cover' // Ensures the image covers the entire area without distortion
//         }} 
//       />
//  <div className="hero-content-1 hero-content-position" style={{ color: '#fff',  zIndex: 1 }}>
//   <h1 style={{ color: "#fff",  fontFamily: "Urbanist, serif", fontWeight: 700,letterSpacing: "4px"   }}>
//     Welcome To <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX INTERIORS </span>
//   </h1>
// </div>
//     </div>
//   );
// }

// export default MainBanner;

import React from 'react';
import bannerVideo from '../img/banner/vedeo1.mp4'; // Replace with the correct path to your video file

const MainBanner = () => {
  return (
    <div
      className="hero-area overly-style-1 opacity-point-4"
      style={{ height: '800px',width:'100%', position: 'relative', overflow: 'hidden' }}
    >

      {/* Video that covers the full screen */}
      <video
        className="banner-video"
        src={bannerVideo}
        autoPlay
        loop
        muted
        playsInline
        style={{
           position: 'absolute',
           top: 0,
           left: 0,
          // width: '100%',
          // height: 'auto',
           objectFit: 'cover', // Ensures the video covers the entire area without distortion
           zIndex: -1,
          
          // Keeps the video behind the content
        }}
      ></video>

      {/* Overlay Content */}
      <div
        className="hero-content-1 hero-content-position"
        style={{ color: '#fff', zIndex: 1, textAlign: 'center' }}
      >
        <h1
          style={{
            color: '#fff',
            fontFamily: 'Urbanist, serif',
            fontWeight: 700,
            letterSpacing: '4px',
          }}
        >
          {' '}
          <span style={{ fontFamily: 'Raqupine Regular' }}></span>
        </h1>
      </div>
    </div>
  );
};

export default MainBanner;

