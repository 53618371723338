import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'; // Import solid icons
import logo from '../img/logo/AA_LOGO.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 

const Footer = () => {
  const navigate = useNavigate();  
  return (
    <footer>
      <div className="container-fluid">
        <div className="row p-3-vh">
          <div className="col-md-4">
            <div className="logo d-flex align-items-center">
              <img alt="logo" src={logo} style={{ maxHeight: '78px' }} />
              <div className="ml-3 text-white"> {/* Added text-white class for white text */}
              <h1 className="m-0" style={{fontFamily: 'Raqupine Regular' }}>MODULUX</h1>
              <p className="m-0">INTERIORS</p>
              </div>
            </div>
            <div className="description">
            "Empowering Your Growth Through Innovative IT Solutions."
            </div>
            <div className="s-social">
              {/* Social Icons */}
              <a href="#"><span className="ti-facebook"></span></a>
              <a href="#"><span className="ti-linkedin"></span></a>
              <a href="#"><span className="ti-instagram"></span></a>
              <a href="#"><span className="ti-youtube"></span></a>
            </div>
          </div>

          <div className="col-md-3">
            <div className="heading">Services</div>
            <div className="s-address">
              <div className="detail">
             
                <Link to="/it-services">Residential Interiors</Link>
              </div>
              <div className="detail">
               
                <Link to="/web-mobile-applications">Commercial Interiors</Link>
              </div>
              <div className="detail">
              
                <Link to="/gaming">Hospitality Interiors</Link>
              </div>
              <div className="detail">
               
                <Link to="/snowcsc">Showcase Interiors</Link>
              </div>
              <div className="detail">
                
                <Link to="/Outdoor">outdoor Environments</Link>
              </div>
              <div className="detail">
               
                <Link to="/Specialty">Specialty Interiors</Link>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="heading">Quick Links</div>
            <div className="s-address">
              <div className="detail">
                <Link to="/">Home</Link>
              </div>
              <div className="detail">
                <Link to="/who-we-are">Who We Are</Link>
              </div>
              <div className="detail">
                <Link to="/what-we-do">The Design</Link>
              </div>
              
              {/* <div className="detail">
                <Link to="/gellerypage">Gallery</Link>
              </div> */}



              {/* <div className="detail">
                <a href="gallery.php">Gallery</a>
              </div> */}
              <div className="detail">
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="heading">Get In Touch</div>
            <div className="s-address">
              <div className="detail">
                <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: '10px' }} />
                contact@aaapplications.com 
              </div>
              <div className="detail">
                <FontAwesomeIcon icon={faPhone} style={{ paddingRight: '10px' }} />
                (330) 474-9389
              </div>
            </div>
          </div>
        </div>

        <div className="row p-3-vh">
          <div className="col-12">
            <div className="subfooter">
              <div className="copyright text-center">
                <p>Copyright©2024  <span style={{fontFamily: 'Raqupine Regular',color:'#fff' }}> MODULEX INTERIOPRS </span>, LLC. All Rights Reserved.</p>
              </div>
              <a className="terms" href="#">
                Terms of use | Privacy Environmental Policy.
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* WhatsApp Button */}
      {/* <a href="https://wa.me/ (330) 474-9389" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className="fab fa-whatsapp" style={{ fontSize: "30px", color: "#fff" }} />
      </a> */}
    </footer>
  );
};

export default Footer;
