// import React from "react";
// import abouthomeImg from "../img/banner/abouthome.jpg"; // Adjust the path as per your project structure
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRocket, faPalette, faHandshake, faCogs, faShieldAlt, faUserCheck } from "@fortawesome/free-solid-svg-icons";

// const WhyChooseUs = () => {
//   return (
//     <section className="why-choose-us-area">
//       <div className="container-fluid">
//         <div className="row">
//           {/* Left Image Column */}
//           <div className="col-lg-5 col-md-12">
//             <div className="why-choose-us-image">
//               <img src={abouthomeImg} alt="About Home" />
//             </div>
//           </div>

//           {/* Right Content Column */}
//           <div className="col-lg-7 col-md-12">
//             <div className="why-choose-us-content">
//               <div className="contents">
//                 <h2
//                   style={{
//                     color: "#fff",
//                     marginBottom: "45px",
//                     textAlign: "center",
//                   }}
//                 >
//                   Why <span style={{ color: "#fff" }}>Choose Us</span>
//                 </h2>
//                 <p>
//                   Transforming Your Vision into Reality with Cutting-Edge
//                   Technology. Discover Why<span style={{fontFamily: 'Raqupine Regular' }}> MODULEX</span>  Interiors is the Right Choice for
//                   Your Design Journey.
//                 </p>

//                 {/* Features List */}
//                 <ul className="features-list">
//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faRocket} />
//                     </div>
//                     <span>Innovative Technology</span>
//                     We utilize cutting-edge virtual reality technology to give
//                     you a fully immersive designs.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faPalette} />
//                     </div>
//                     <span>Personalized Design</span>
//                     We understand that every client is unique, and so are their
//                     design needs.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faHandshake} />
//                     </div>
//                     <span>Expert Team</span>
//                     Our team of skilled designers and VR specialists bring years
//                     of experience and creativity.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faCogs} />
//                     </div>
//                     <span>Seamless Experience</span>
//                     From concept to completion, we ensure a smooth and
//                     stress-free design process.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faShieldAlt} />
//                     </div>
//                     <span>Commitment to Quality</span>
//                     We are dedicated to delivering high-quality designs that not
//                     only look stunning.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faUserCheck} />
//                     </div>
//                     <span>Customer Satisfaction</span>
//                     Your satisfaction is our top priority to ensure that your
//                     experience with us is positive.
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhyChooseUs;



// import React from 'react';
// import collageImage from '../img/banner/abouthome.jpg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faRocket,
//   faPalette,
//   faHandshake,
//   faCogs,
//   faShieldAlt,
//   faUserCheck,
// } from '@fortawesome/free-solid-svg-icons'; // Import icons

// const WhyChooseUs = () => {
//   return (
//     <section className="why-choose-us-area">
//       <div className="container-fluid">
//         <div className="row">
//           {/* Image Section */}
//           <div className="col-lg-5 col-md-12">
//             <div className="why-choose-us-image">
//               <img src={collageImage} alt="About Home" />
//             </div>
//           </div>

//           {/* Content Section */}
//           <div className="col-lg-7 col-md-12">
//             <div className="why-choose-us-content">
//               <div className="contents">
//                 <h2 style={{ color: '#fff', marginBottom: '45px', textAlign: 'center' }}>
//                   Why <span style={{ color: '#fff' }}>Choose Us</span>
//                 </h2>
//                 <p>
//                   Transforming Your Vision into Reality with Cutting-Edge Technology. Discover Why
//                   VRInteriors is the Right Choice for Your Design Journey.
//                 </p>

//                 {/* Features List */}
//                 <ul className="features-list">
//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faRocket} />
//                     </div>
//                     <span>Innovative Technology</span>
//                     We utilize cutting-edge virtual reality technology to give you fully immersive
//                     designs.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faPalette} />
//                     </div>
//                     <span>Personalized Design</span>
//                     We understand that every client is unique, and so are their design needs.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faHandshake} />
//                     </div>
//                     <span>Expert Team</span>
//                     Our team of skilled designers and VR specialists bring years of experience and
//                     creativity.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faCogs} />
//                     </div>
//                     <span>Seamless Experience</span>
//                     From concept to completion, we ensure a smooth and stress-free design process.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faShieldAlt} />
//                     </div>
//                     <span>Commitment to Quality</span>
//                     We are dedicated to delivering high-quality designs that not only look stunning.
//                   </li>

//                   <li>
//                     <div className="icon">
//                       <FontAwesomeIcon icon={faUserCheck} />
//                     </div>
//                     <span>Customer Satisfaction</span>
//                     Your satisfaction is our top priority to ensure that your experience with us is
//                     positive.
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhyChooseUs;




import React from 'react';
import collageImage from '../img/banner/abouthome.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRocket,
  faPalette,
  faHandshake,
  faCogs,
  faShieldAlt,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us-area">
      <div className="container-fluid">
        <div className="row">
          {/* Image Section */}
          <div className="col-lg-5 col-md-12">
            <div className="why-choose-us-image">
              <img
                src={collageImage}
                alt="About Home"
                style={{ maxHeight: "790px" }}
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-7 col-md-12">
            <div className="why-choose-us-content">
              <div className="contents">
                <h2 style={{ color: '#fff', marginBottom: '45px', textAlign: 'center',fontWeight:'700;' }}>
                  Why <span style={{ color: '#fff' }}>Choose Us</span>
                </h2>
                <p>
                  <span style={{ fontFamily: 'Lato, sans-serif',fontSize:'18px' }}>
                    Transforming Your Vision into Reality with Cutting-Edge Technology. Discover Why
                  </span>

                  <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>Interiors is the Right Choice for Your Design Journey.
                </p>

                {/* Features List */}
                <ul className="features-list">
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faRocket} />
                    </div>
                    <span>Innovative Technology</span>
                    We utilize cutting-edge virtual reality technology to give you fully immersive
                    designs.
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPalette} />
                    </div>
                    <span>Personalized Design</span>
                    We understand that every client is unique, and so are their design needs.
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <span>Expert Team</span>
                    Our team of skilled designers   MODULEX  specialists bring years of experience and
                    creativity.
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCogs} />
                    </div>
                    <span>Seamless Experience</span>
                    From concept to completion, we ensure a smooth and stress-free design process.
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faShieldAlt} />
                    </div>
                    <span>Commitment to Quality</span>
                    We are dedicated to delivering high-quality designs that not only look stunning.
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faUserCheck} />
                    </div>
                    <span>Customer Satisfaction</span>
                    Your satisfaction is our top priority to ensure that your experience with us is
                    positive.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;



