// import React from "react";
// import Slider from "react-slick";
// import residentialImg from "../img/banner/gallery.jpg";
// import cmImg from "../img/banner/spaciality-img.avif";
// import hospitalityImg from "../img/home4.jpg";
// import sshImg from "../img/Hospitality Interiors 3.jpg";
// import specialtyImg from "../img/Untitled-6.jpg";
// import galleryImg6 from "../img/ssh-img.png";
// import galleryImg7 from "../img/image-7.jpg";
// import "slick-carousel/slick/slick.css";
//  import "slick-carousel/slick/slick-theme.css";

// const GallerySlider = () => {
//   const settings = {
//     dots: true, // Enable dots for navigation
//     infinite: true, // Infinite loop
//     speed: 500, // Transition speed
//     slidesToShow: 1, // Number of slides visible at a time
//     slidesToScroll: 1, // Number of slides to scroll at a time
//     autoplay: true, // Enable auto-slide
//     autoplaySpeed: 3000, // Auto-slide interval (3 seconds)
//     pauseOnHover: true, // Pause sliding on hover
//   };

//   return (
//     <section style={{ marginTop: "30px", backgroundColor: "#dae2e3" }}>
//       <div className="container-fluid" style={{ padding: "0px" }}>
//         <div className="row">
//           <div className="col-lg-12">
//             <Slider {...settings}>
//               <div className="item">
//                 <img
//                   src={residentialImg}
//                   alt="Residential Interiors"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={cmImg}
//                   alt="Commercial Interiors"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={hospitalityImg}
//                   alt="Hospitality Interiors"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={sshImg}
//                   alt="SSH Interiors"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={specialtyImg}
//                   alt="Specialty Interiors"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={galleryImg6}
//                   alt="Gallery Image 6"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//               <div className="item">
//                 <img
//                   src={galleryImg7}
//                   alt="Gallery Image 7"
//                   style={{ height: "600px", width: "100%" }}
//                 />
//               </div>
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default GallerySlider;



import React from "react";
import Slider from "react-slick";
import residentialImg from "../img/banner/gallery.jpg";
import cmImg from "../img/banner/spaciality-img.avif";
import hospitalityImg from "../img/home4.jpg";
import sshImg from "../img/Hospitality Interiors 3.jpg";
import specialtyImg from "../img/Untitled-6.jpg";
import galleryImg6 from "../img/ssh-img.png";
import galleryImg7 from "../img/image-7.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GallerySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false, // Disable next/prev arrows
  };

  return (
    <section style={{ marginTop: "5px", backgroundColor: "#dae2e3" }}>
      <div className="container-fluid" style={{ padding: "0px", overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-12">
            <Slider {...settings}>
              <div className="item">
                <img
                  src={residentialImg}
                  alt="Residential Interiors"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={cmImg}
                  alt="Commercial Interiors"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={hospitalityImg}
                  alt="Hospitality Interiors"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={sshImg}
                  alt="SSH Interiors"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={specialtyImg}
                  alt="Specialty Interiors"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={galleryImg6}
                  alt="Gallery Image 6"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={galleryImg7}
                  alt="Gallery Image 7"
                  style={{ height: "600px", width: "100%" }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySlider;

