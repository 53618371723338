import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import logo from '../img/logo/AA_LOGO.png';
import logo1 from '../img/logo/AA_LOGO.png';

const Nav = () => {
  const [navbarBackground, setNavbarBackground] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For toggle
  const location = useLocation(); // Get current location

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setNavbarBackground(true);
      } else {
        setNavbarBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar state
  };

  return (
    <>
      {/* Navbar Header */}
      <header className="init">
        <div className={`navbar-default-white navbar-fixed-top ${navbarBackground ? 'navbar-scrolled' : ''}`}>
          <div className="container-fluid">
            <div className="row p-3-vh align-items-center">
              {/* Logo and Text */}
              <Link className="logo centered d-flex align-items-center" to="/">
                {/* <img className="h-150 init" alt="logo" src={logo} />
                <img className="h-150 show" alt="logo" src={logo1} /> */}
                <div className="ml-3 text-white">
                  <Link to="/" className="text-white text-decoration-none">
                    <h1 className="m-0 fontsmall" style={{fontFamily: 'Raqupine Regular' }}>MODULUX</h1>
                    <p className="m-0 fontmini" >INTERIORS</p>
                  </Link>
                </div>
              </Link>
              {/* Main Menu */}
              <div className="white menu-init" id="main-menu">
                <nav id="menu-right">
                  <ul>
                    <li>
                      <Link
                        to="/"
                        className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/who-we-are"
                        className={`nav-link ${location.pathname === '/who-we-are' ? 'active-link' : ''}`}
                      >
                        Who we are
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/what-we-do"
                        className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
                      >
                        The Design
                      </Link>
                    </li>
                    
                      {/* <Link
                      to ="/gellerypage"
                      className={`nav-link ${location.pathname === '/gellerypage' ? 'active-link':''}`}>
                       GALLERY
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/contact"
                        className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
                      >
                        Contact
                      </Link>
                    </li>
                    {/* Social Media Icons */}
                    <li className="icons-menu">
                      <Link to="https://x.com/applications_it" target='_blank'><span className="ti-twitter"></span></Link>
                    </li>
                    <li className="icons-menu">
                      <Link to="https://www.linkedin.com/feed/?trk=onboarding-landing" target="_blank" rel="noopener noreferrer">
                        <span className="ti-linkedin"></span>
                      </Link>
                    </li>
                    <li className="icons-menu">
                      <Link to="https://www.instagram.com/appli_cation123/"  target='_blank'><span className="ti-instagram"></span></Link>
                    </li>
                    <li className="icons-menu">
                      <Link to="https://www.youtube.com/feed/you" target='_blank'><span className="ti-youtube"></span></Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* Sidebar Toggle Button for Large Screens */}
              <div className="menu-right centered d-none d-md-block">
                <ul className="iconright">
                  <li>
                    <div onClick={toggleSidebar} className="toggle-button">
                      <span className="ti-menu"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar for Large Screens */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <ul>
            <li>
              <Link
                to="/"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/who-we-are"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/who-we-are' ? 'active-link' : ''}`}
              >
                Who we are
              </Link>
            </li>
            <li>
              <Link
                to="/what-we-do"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
              >
                The Design
              </Link>
            </li>
            {/* <li>
              <Link
                to="/gellerypage"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
              >
                Gellery
              </Link>
            </li> */}

            <li>
              <Link
                to="/contact"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Sidebar and Active Link CSS */}
       {/* Sidebar and Active Link CSS */}
       <style jsx>{`
         .sidebar {
          position: fixed;
          top: 0;
          right: -250px; /* Start off-screen */
          width: 250px;
          height: 100%;
          background-color: #313840;
          transition: right 0.3s ease-in-out; /* Smooth transition */
          z-index: 1000; /* Ensure it stays on top */
        }

        .sidebar.open {
          right: 0; /* Slide in */
          width:100%;
        }

        .sidebar-content ul {
          padding: 0;
          list-style: none;
          margin: 0;
        }

        .sidebar-content ul li {
          padding: 15px 20px;
          border-bottom: 1px solid #444;
        }

        .sidebar-content ul li a {
          color: white;
          text-decoration: none;
        }

        .toggle-button {
          cursor: pointer;
        }

        .dropdown-toggle {
          background: none;
          border: none;
          color: white;
          padding: 10px;
          cursor: pointer;
          width: 100%;
          text-align: left;
        }

        .dropdown-menu {
          list-style: none;
          padding: 0;
          margin: 0;
          background-color: #444;
        }

        // .dropdown-menu li {
        //   padding: 10px 20px;
        // }

        .dropdown-menu li a {
          color: white;
          text-decoration: none;
        }

        .dropdown-menu li a:hover {
          color: #f76046;
        }

        .active-link {
          font-weight: bold;
          // color: #f76046;
        }

        /* Show toggle button and sidebar on larger screens */
        @media (min-width: 1200px) {
          .sidebar {
            display: none; /* Hide sidebar */
          }

          .toggle-button {
            display: block; /* Show toggle button */
          }

          /* Dropdown for mobile */
          .dropdown-toggle {
            display: none; /* Hide dropdown button on larger screens */
          }
        }

        /* For smaller screens (mobile view) */
        @media (max-width: 767px) {
          .sidebar-content ul li {
            padding: 10px 20px;
          }

          .dropdown-menu {
            display: block;
            background-color: #313840;
            border-top: 1px solid #313840;
          }

          .dropdown-menu li a {
            padding-left: 40px; /* Indent dropdown items */
          }

          .sidebar-content ul li .dropdown-menu li {
            padding: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default Nav;
