import React, { useEffect, useState } from 'react';
import bannerImage from '../img/banner/Hospitality Interiors 3.jpg';
import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png';
import abouthome1 from '../img/banner/Hospitality1.png'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import galleryImg1 from '../img/3.jpg';
import galleryImg2 from '../img/11.jpg';
import galleryImg3 from '../img/3.jpg';
import galleryImg4 from '../img/11.jpg';
import galleryImg5 from '../img/5.jpg';
import galleryImg6 from '../img/1.jpg';
import galleryImg7 from '../img/7.jpg';
import galleryImg8 from '../img/8.jpg';
import galleryImg9 from '../img/9.jpg';
import galleryImg10 from '../img/10.jpg';
import ReactImageLightbox from 'react-image-lightbox';
const WhatWeDo = () => {

  const [navbarHeight, setNavbarHeight] = useState(93);
  const [selectedIcon, setSelectedIcon] = useState('RESIDENTIAL_INTERIORS'); // Default selected content
  const [activeFilter, setActiveFilter] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);



  const galleryItems = [
    { category: 'Coworking', imgSrc: galleryImg1, alt: 'Co-working space' },
    { category: 'virtual-office', imgSrc: galleryImg2, alt: 'Virtual Office' },
    { category: 'dedicated-desk', imgSrc: galleryImg3, alt: 'Dedicated Desk' },
    { category: 'private-office', imgSrc: galleryImg4, alt: 'Private Office' },
    { category: 'meeting-spaces', imgSrc: galleryImg5, alt: 'Meeting Spaces' },
    { category: 'Coworking', imgSrc: galleryImg6, alt: 'Co-working space' },
    { category: 'virtual-office', imgSrc: galleryImg7, alt: 'Virtual Office' },
    { category: 'dedicated-desk', imgSrc: galleryImg8, alt: 'Dedicated Desk' },
    { category: 'private-office', imgSrc: galleryImg9, alt: 'Private Office' },
    { category: 'meeting-spaces', imgSrc: galleryImg10, alt: 'Meeting Spaces' }
  ];

  const filteredItems = galleryItems.filter(item => activeFilter === 'all' || item.category === activeFilter);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const renderContent = () => {
    switch (selectedIcon) {
      case 'THE_DESIGN':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    > MODULEX INTERIORS
                    </span>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing.
                      .
                    </p>
                  </div>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                  </p>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      case 'COMMERCIAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      COMMERCIAL <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome.
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> Whether it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      case 'HOSPITALITY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome1} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      HOSPITALITY <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Our Hospitality Interiors are designed to create unforgettable experiences for guests, blending luxury and comfort with functional elegance. We specialize in crafting spaces that cater to the unique needs of hotels, resorts, and restaurants, ensuring each area—from lobbies to guest rooms—exudes warmth and sophistication..
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> Our designs incorporate cutting-edge technology, high-quality materials, and thoughtful layouts to enhance both aesthetics and efficiency. Whether it's a serene spa, a stylish dining area, or a welcoming reception, we focus on creating environments that leave a lasting impression, making every stay or visit a memorable one. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      case 'SHOWCASE_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      SHOWCASE <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Showcase Spaces are designed to captivate and engage visitors, showcasing your products or art in the best possible light. With flexible layouts and modular displays, we ensure that your space adapts to changing needs while maintaining visual appeal. From car showrooms to art galleries, we incorporate advanced lighting, innovative shelving, and cutting-edge technology to enhance the visitor experience. Our designs focus on both aesthetics and functionality,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> providing a seamless flow of traffic while highlighting key features. Let us help you create an environment that leaves a lasting impression on your audience. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div> 
        
      case 'SPECIALTY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      SPECIALTY <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Specialty Interiors focus on creating unique spaces tailored to specific needs and interests. Whether designing a state-of-the-art Home Theater with immersive audio-visual experiences, a dynamic Game Room with cutting-edge gaming setups, or a fully automated Smart Home integrating the latest technology for convenience and efficiency,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> our designs are crafted to enhance every aspect of your lifestyle. We also specialize in Custom Workspaces that blend functionality with style to boost productivity. Each project is meticulously planned to reflect your personal preferences while ensuring maximum comfort and innovation </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      case 'OUTDOOR_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      OUTDOOR <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      case 'RESIDENTAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      RESIDENTIAL <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>


      default:
        return <div><section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    > MODULEX INTERIORS
                    </span>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div className="gallery-area ptb-100">
            <div className="container-fluid">
              <div className="shorting">
                <div className="row">
                  {filteredItems.map((item, index) => (
                    <div key={index} className={`single-gallery-box mix ${item.category}`}>
                      <img src={item.imgSrc} alt={item.alt} />
                      <a
                        href="#!"
                        className="gallery-btn"
                        onClick={() => openLightbox(index)}
                      >
                        <i className="flaticon-expand"></i>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div></div>;
    }
  };

  return (


    <div>
      {/* Hero Section */}
      <div>
        {/* Hero Section */}
        <div>
          <div className="hero-area overly-style-1 opacity-point-4" style={{ position: 'relative' }}>
            <img
              className="banner-image"
              src={bannerImage}
              alt="About AA Applications Banner"
              style={{ width: '100%', height: 'auto' }}
            />

            {/* Content Wrapper */}
            <div
              className="hero-content-wrapper"
              style={{
                display: 'flex',          // Use Flexbox for layout
                justifyContent: 'space-between',  // Space between text and form
                alignItems: 'center',     // Center vertically
                position: 'absolute',     // Position over the image
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',             // Adjust width as needed
              }}
            >
              {/* Left Content - THE DESIGN Text */}
              <div className="hero-text" style={{ flex: '1' }}>
                <h1 style={{ color: '#fff', fontSize: '48px', fontFamily: 'Raqupine Regular' }}>
                  THE DESIGN
                </h1>
              </div>

              {/* Right Content - Form */}
              <div
                className="transparent-form"
                style={{
                  background: 'rgba(255, 255, 255, 0.2)',
                  padding: '20px',
                  borderRadius: '8px',
                  backdropFilter: 'blur(8px)',
                  color: '#000',
                  zIndex: 1000,
                  width: '300px',
                }}
              >
                {/* Close Button */}
                <button
                  className="close-button"
                  onClick={() => {
                    document.querySelector('.transparent-form').style.display = 'none';
                  }}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    color: '#000',
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </button>

                {/* Welcome Text */}
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  <h2 style={{ margin: '0', fontFamily: 'Raqupine Regular ' }}>Welcome</h2>
                </div>

                {/* Form Content */}
                <form>
                  {/* Name Field */}
                  <div style={{ marginBottom: '10px', position: 'relative' }}>
                    <label>Name</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i
                        className="fas fa-user"
                        style={{
                          marginRight: '8px',
                          color: '#000',
                        }}
                      ></i>
                      <input
                        type="text"
                        placeholder="Enter your name"
                        style={{
                          width: '100%',
                          background: 'transparent',
                          border: 'none',
                          borderBottom: '1px solid #fff',
                          color: '#000',
                          outline: 'none',
                          padding: '5px 0',
                        }}
                      />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div style={{ marginBottom: '10px', position: 'relative' }}>
                    <label>Email</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i
                        className="fas fa-envelope"
                        style={{
                          marginRight: '8px',
                          color: '#000',
                        }}
                      ></i>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        onBlur={(e) => {
                          const emailPattern =
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                          const isValidEmail = emailPattern.test(e.target.value);
                          if (!isValidEmail && e.target.value) {
                            alert('Please enter a valid email address');
                          }
                        }}
                        style={{
                          width: '100%',
                          background: 'transparent',
                          border: 'none',
                          borderBottom: '1px solid #fff',
                          color: '#000',
                          outline: 'none',
                          padding: '5px 0',
                        }}
                      />
                    </div>
                  </div>

                  {/* Phone Field */}
                  <div style={{ marginBottom: '10px', position: 'relative' }}>
                    <label>Phone</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i
                        className="fas fa-phone"
                        style={{
                          marginRight: '8px',
                          color: '#000',
                        }}
                      ></i>
                      <input
                        type="tel"
                        placeholder="Enter your phone number"
                        maxLength="10"
                        onChange={(e) => {
                          const phone = e.target.value;
                          if (!/^\d*$/.test(phone)) {
                            alert('Phone number must be numeric.');
                          }
                          if (phone.length > 10) {
                            alert('Phone number cannot exceed 10 digits.');
                          }
                        }}
                        style={{
                          width: '100%',
                          background: 'transparent',
                          border: 'none',
                          borderBottom: '1px solid #fff',
                          color: '#000',
                          outline: 'none',
                          padding: '5px 0',
                        }}
                      />
                    </div>
                  </div>
                </form>

                {/* Enter Now Button */}
                <div style={{ textAlign: 'center', marginTop: '15px' }}>
                  <button
                    style={{
                      background: '#fff',
                      color: '#333',
                      border: 'none',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      fontSize: '16px',
                    }}
                  >
                    Enter Now
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>






      {/* Sticky Icon Slider */}
      <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: 'sticky',
            top: `${navbarHeight}px`,
            backgroundColor: '#def2f7',
            zIndex: 997,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            height: '100px',
            width: '100%'
          }}
        >
          <Slider {...settings}>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('RESIDENTAL_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>RESIDENTAL INTERIORS</p>
              </a>
            </div>



            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('COMMERCIAL_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>COMMERCIAL INTERIORS</p>
              </a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('HOSPITALITY_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>HOSPITALITY INTERIORS</p>
              </a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('SHOWCASE_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('OUTDOOR_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('SPECIALTY_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>
          </Slider>
        </div>

        {isOpen && (
          <ReactImageLightbox
            mainSrc={filteredItems[currentImage].imgSrc}
            nextSrc={filteredItems[(currentImage + 1) % filteredItems.length].imgSrc}
            prevSrc={filteredItems[(currentImage + filteredItems.length - 1) % filteredItems.length].imgSrc}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setCurrentImage((currentImage + filteredItems.length - 1) % filteredItems.length)
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % filteredItems.length)
            }
          />
        )}
        {/* Dynamic Content Section */}
        <div className="dynamic-content-section" style={{ padding: '20px' }}>
          {renderContent()}
        </div>
      </div>
    </div>


  );
};

export default WhatWeDo;
