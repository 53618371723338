import React, { useEffect } from 'react'
import GalleryBanner from './GalleryBanner'
import Gallery from './Gallery'
// import ContactMap from './ContactMap'

const Contactus = () => {
       // Scroll to the top of the page when the component mounts
       useEffect(() => {
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures it only runs once when the component mounts
    
  return (
    <div>
       <GalleryBanner/>
       <Gallery/>
        {/* <ContactForm/> */}
        {/* <ContactMap/> */}
    </div>
  )
}

export default Contactus