import React from 'react';
import breadcumb from '../img/banner/gallery.jpg'; // Assuming the image is in this path

const GalleryBanner = () => {
  return (
    <section
      className="breadcumb"
      aria-label="breadcumb"
      style={{ backgroundImage: `url(${breadcumb})` }} // Corrected template literal
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main">
              <div className="bread">
                <div className="bread-title" style={{ textTransform:'uppercase', fontSize:'3.5rem',fontweight:'700',letterSpacing:'2px',display:'block' }}>GALLERY</div>
                <div className="bread-subtitle" style={{marginTop:'0px'}}> Crafting Spaces, Capturing Moments </div>
                  {/* Breadcrumb navigation (optional) */}
                  {/* <a href="/">Home</a>
                  <span className="spacebread"></span>
                  <span>Contact us</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </section>
  );
};

export default GalleryBanner;




// import React from 'react';
// import breadcumb from '../img/banner/gallery.jpg'; // Assuming the image is in this path

// const GalleryBanner = () => {
//   // Styles
//   const sectionStyle = {
//     backgroundImage: `url(${breadcumb})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundRepeat: 'no-repeat',
//     padding: '50px 0',
//     color: '#fff',
    
//     textAlign: 'center',
//   };

//   const breadTitleStyle = {
//     textTransform: 'uppercase',
//     fontSize: '3.5rem',
//     fontWeight: '700',
//     letterSpacing: '2px',
//     marginBottom: '10px',
//   };

//   const breadSubtitleStyle = {
//     marginTop: '0px',
//     fontSize: '1.2rem',
//   };

//   const containerStyle = {
//     maxWidth: '1200px',
//     margin: '0 auto',
//     padding: '0 15px',
//   };

//   return (
//     <section className="breadcumb" aria-label="breadcumb" style={sectionStyle}>
//       <div className="container-fluid" style={containerStyle}>
//         <div className="row">
//           <div className="col-12">
//             <div className="main">
//               <div className="bread">
//                 <div className="bread-title" style={breadTitleStyle}>
//                   GALLERY
//                 </div>
//                 <div className="bread-subtitle" style={breadSubtitleStyle}>
//                   Crafting Spaces, Capturing Moments
//                 </div>
//                 {/* Optional breadcrumb navigation */}
//                 {/* <a href="/">Home</a>
//                 <span className="spacebread"></span>
//                 <span>Contact us</span> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default GalleryBanner;

