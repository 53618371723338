



import React, { useState } from 'react';
import Slider from 'react-slick';
import 'react-image-lightbox/style.css'; // Import the lightbox styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import galleryImg1 from '../img/3.jpg';
import galleryImg2 from '../img/11.jpg';
import galleryImg3 from '../img/3.jpg';
import galleryImg4 from '../img/11.jpg';
import galleryImg5 from '../img/5.jpg';
import galleryImg6 from '../img/1.jpg';
import galleryImg7 from '../img/7.jpg';
import galleryImg8 from '../img/8.jpg';
import galleryImg9 from '../img/9.jpg';
import galleryImg10 from '../img/10.jpg';
import ReactImageLightbox from 'react-image-lightbox';

const Gallery = () => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const galleryItems = [
    { category: 'Coworking', imgSrc: galleryImg1, alt: 'Co-working space' },
    { category: 'virtual-office', imgSrc: galleryImg2, alt: 'Virtual Office' },
    { category: 'dedicated-desk', imgSrc: galleryImg3, alt: 'Dedicated Desk' },
    { category: 'private-office', imgSrc: galleryImg4, alt: 'Private Office' },
    { category: 'meeting-spaces', imgSrc: galleryImg5, alt: 'Meeting Spaces' },
    { category: 'Coworking', imgSrc: galleryImg6, alt: 'Co-working space' },
    { category: 'virtual-office', imgSrc: galleryImg7, alt: 'Virtual Office' },
    { category: 'dedicated-desk', imgSrc: galleryImg8, alt: 'Dedicated Desk' },
    { category: 'private-office', imgSrc: galleryImg9, alt: 'Private Office' },
    { category: 'meeting-spaces', imgSrc: galleryImg10, alt: 'Meeting Spaces' }
  ];

  const filteredItems = galleryItems.filter(item => activeFilter === 'all' || item.category === activeFilter);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {  
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* Icon Slider Above Gallery */}
      <div
        className="icon-slider-wrapper"
        style={{
          position: "sticky",
          top: "93px",
          backgroundColor: "#def2f7",
          zIndex: "1000",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          height: "100px",
        }}
      >
        <Slider {...settings}>
          {/* Residential Interiors */}
          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/it-services"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon icon={faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>RESIDENTIAL INTERIORS</p>
            </a>
          </div>
          {/* Commercial Interiors */}
          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/web-mobile-applications"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>COMMERICAL INTERIORS</p>
            </a>
          </div>
          {/* Add other icons here in similar fashion */}
          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/gaming"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon  icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>HOSPITALITY INTERIORS</p>
            </a>
          </div>



          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/snowcsc"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon  icon={faTree}style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>SHOWCASC INTERIORS</p>
            </a>
          </div>



          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/Specialty"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>SPECIALTY INTERIORS</p>
            </a>
          </div>


          <div style={{ textAlign: "center" }}>
            <a
              className="filter"
              onClick={() => window.location.href = "/Outdoor"}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "33px",
              }}
            >
              <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
              <p>OUTDOOR INTERIORS</p>
            </a>
          </div>



        </Slider>
      </div>
 
  
      <div className="gallery-area ptb-100">
        <div className="container-fluid">
          <div className="shorting">
            <div className="row">
              {filteredItems.map((item, index) => (
                <div key={index} className={`single-gallery-box mix ${item.category}`}>
                  <img src={item.imgSrc} alt={item.alt} />
                  <a
                    href="#!"
                    className="gallery-btn"
                    onClick={() => openLightbox(index)}
                  >
                    <i className="flaticon-expand"></i>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> 




      {/* Lightbox */}
      {isOpen && (
        <ReactImageLightbox
          mainSrc={filteredItems[currentImage].imgSrc}
          nextSrc={filteredItems[(currentImage + 1) % filteredItems.length].imgSrc}
          prevSrc={filteredItems[(currentImage + filteredItems.length - 1) % filteredItems.length].imgSrc}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + filteredItems.length - 1) % filteredItems.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % filteredItems.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;

