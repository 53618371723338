import React from 'react';
import breadcumb from '../img/banner/call4.jpg'; // Assuming the image is in this path

const ContactBanner = () => {
  return (
    <section
      className="breadcumb"
      aria-label="breadcumb"
      style={{ backgroundImage: `url(${breadcumb})` }} // Corrected template literal
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main">
              <div className="bread">
                <div className="bread-title" style={{fontFamily:'Raqupine Regular',marginTop:'-50px',}}>Contact us</div>
                <div className="bread-subtitle">
                  {/* Breadcrumb navigation (optional) */}
                  {/* <a href="/">Home</a>
                  <span className="spacebread"></span>
                  <span>Contact us</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBanner;
