

import React, { useEffect, useState } from 'react';
import bannerImage from '../img/banner/cm-img.png';
import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom'; 
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';





const WebApplications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();  // Use the navigate function
  const [navbarHeight, setNavbarHeight] = useState(93); // Default navbar height
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {/* Hero/Banner Section */}
      <div>
        <div className="hero-area overly-style-1 opacity-point-4">
          <img
            className="banner-image"
            src={bannerImage}
            alt="About AA Applications Banner"
            style={{ width: '100%', height: 'auto' }}
          />
          <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
            <h1 style={{ color: '#fff', fontSize: '48px' }}>COMMERCIAL INTERIORS</h1>
            <div className="bread-subtitle" style={{ marginTop: '0px' }}>
              Transforming Commercial Spaces with Virtual Vision
            </div>
          </div>
        </div>
      </div>
   {/* Sticky Icon Slider */}
   <div>
   <div
          className="icon-slider-wrapper"
          style={{
            position: "sticky",
            top: `${navbarHeight}px`,
            backgroundColor: "#def2f7",
            zIndex: 997,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "100px",
            width: '100%'
          }}
          >
          <Slider {...settings}>
            {/* Residential Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/it-services')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>RESIDENTIAL INTERIORS</p>
              </a>
            </div>
            {/* Commercial Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"


                onClick={() => navigate('/web-mobile-applications')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>COMMERCIAL INTERIORS</p>
              </a>
            </div>
            {/* Hospitality Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/gaming')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>HOSPITALITY INTERIORS</p>
              </a>
            </div>
            {/* Showcase Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/snowcsc')}  // Use navigate to go to the path
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>


            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/Specialty')} 
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>

            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/outdoor')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>
          </Slider>
        </div>
      

      <section className="no-top no-bottom bggray" aria-label="abouthome">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-0">
              <img src={abouthome} className="imgbg-col" alt="imghome" />
            </div>
            <div className="col-md-6 centered">
              <div className="detailcontent">
                <div className="subheading" style={{  fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
                <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      COMMERCIAL <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                </div>
                {/* <div className="heading">Best Interior is Our Passion</div> */}
                <div className="textdetail">
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>  interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome. 

                  </p>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                  </p>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>Whether it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals.  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</div>
    </div>
  );
};

export default WebApplications;



//  import React,{useEffect} from 'react'
//  import bannerImage from '../img/banner/cm-img.png';
//  import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png'
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//   import Slider from 'react-slick';
//   import { faUsers, faGlobe, faChalkboard, faMapMarkerAlt, faThLarge } from '@fortawesome/free-solid-svg-icons';
//  import ServicesCards from './ServicesCards';


// const WebApplications = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       {/* Hero/Banner Section */}
//       <div>
//         <div className="hero-area overly-style-1 opacity-point-4">
//           <img
//             className="banner-image"
//             src={bannerImage}
//             alt="About AA Applications Banner"
//             style={{ width: '100%', height: 'auto' }}
//           />
//           <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
//             <h1 style={{ color: '#fff', fontSize: '48px' }}>COMMERCIAL INTERIORS</h1>
//             <div className="bread-subtitle" style={{ marginTop: '0px' }}>
//               Transforming Commercial Spaces with Virtual Vision
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Icon Slider Section */}
//       <div
//         className="icon-slider-wrapper"
//         style={{
//           backgroundColor: "#def2f7",
//           boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//           padding: "15px 0",
//         }}
//       >
//         <Slider {...settings}>
//           <div style={{ textAlign: "center" }}>
//             <a
//               className="filter"
//               onClick={() => (window.location.href = "/it-services")}
//               style={{
//                 textDecoration: "none",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//               }}
//             >
//               <FontAwesomeIcon icon={faUsers} style={{ marginBottom: "5px", fontSize: "24px" }} />
//               <p>RESIDENTIAL INTERIORS</p>
//             </a>
//           </div>
//           {/* Add the rest of your icons here */}
//         </Slider>
//       </div>

//       {/* Content Section */}
//       <section className="no-top no-bottom bggray" aria-label="abouthome">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-md-6 p-0">
//               <img src={abouthome} className="imgbg-col" alt="imghome" />
//             </div>
//             <div className="col-md-6 centered">
//               <div className="detailcontent">
//                 <div
//                   className="subheading"
//                   style={{
//                     color: '#1c1d1d',
//                     fontSize: '30px',
//                     textAlign: 'center',
//                     marginBottom: '11px',
//                   }}
//                 >
//                   About <span style={{ color: '#1c1d1d', fontSize: '30px' }}>COMMERCIAL INTERIORS</span>
//                 </div>
//                 <div className="textdetail">
//                   <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                     <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX</span> interiors, we specialize in creating
//                     functional and aesthetically pleasing commercial interiors that enhance productivity and reflect
//                     your brand’s identity.
//                   </p>
//                   <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                     <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX</span> it’s a modern office with flexible
//                     workstations or a chic retail space with engaging displays, our tailored solutions ensure your
//                     commercial interiors are both practical and inspiring, perfectly aligned with your business goals.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <ServicesCards />
//     </div>
//   );
// };

// export default WebApplications;
