import React, { useEffect, useState } from 'react';
import bannerImage from '../img/banner/image-9.jpg';
import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png';
import { useNavigate } from 'react-router-dom';  // Make sure this is imported
import ServicesCards from './ServicesCards';
import 'react-image-lightbox/style.css'; // Import the lightbox styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-slick';
// import Gellerycomponant from '../src/components/Gellerycomponant';




const Itservices = () => {
  const [navbarHeight, setNavbarHeight] = useState(93); // Default navbar height
  const navigate = useNavigate();  // Use the navigate function
  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // Calculate navbar height
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/* Hero Section */}
      <div>
        <div className="hero-area overly-style-1 opacity-point-4">
          <img
            className="banner-image"
            src={bannerImage}
            alt="About AA Applications Banner"
            style={{ width: '100%', height: 'auto' }}
          />
          <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
            <h1 style={{ color: "#fff", fontSize: '48px', marginTop: '373px' }}>RESIDENTIAL INTERIORS</h1>
            <div className="bread-subtitle" style={{ marginTop: '0px' }}>
              Crafting Your Ideal Home In Virtual Reality
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Icon Slider */}
      <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: "sticky",
            top: `${navbarHeight}px`,
            backgroundColor: "#def2f7",
            zIndex: 997,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "100px",
            width: '100%'
          }}
          >
          <Slider {...settings}>
            {/* Residential Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/it-services')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>RESIDENTIAL INTERIORS</p>
              </a>
            </div>
            {/* Commercial Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"


                onClick={() => navigate('/web-mobile-applications')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>COMMERCIAL INTERIORS</p>
              </a>
            </div>
            {/* Hospitality Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/gaming')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>HOSPITALITY INTERIORS</p>
              </a>
            </div>
            {/* Showcase Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/snowcsc')}  // Use navigate to go to the path
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>


            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/Specialty')} 
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>

            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/outdoor')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>
          </Slider>
        </div>


        {/* About Section */}
        <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      RESIDENTIAL <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>

                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>  Interiors, we transform your design visions into reality using cutting-edge Virtual Reality technology. We specialize in immersive experiences for a variety of interiors, including Residential Interiors like living rooms, kitchens, and bedrooms, as well as Commercial Spaces such as offices, retail stores, and restaurants. Our services extend to Hospitality Interiors, covering hotels and resorts, and specialty interiors like home theaters, game rooms, and smart homes.

                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> We also offer outdoor and landscape designs that elevate your living experience. Our focus is on blending creativity and innovation to deliver customized spaces that reflect your unique style. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <Gellerycomponant/> */}
    </div>
  );
};

export default Itservices;
