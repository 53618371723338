// import React from 'react';

// const OurCommitments = () => {
//   return (
//     <section id="commitment" style={{ paddingTop: '40px' }}>
//       <div className="container">
//         <div className="row">
//           <section id="cards-containers" style={{ textAlign: 'center' }}>
//             <div className="container">
//             <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
//                       Our <span style={{ color: "#3395AD" }}>Commitments</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//             </div>
//             {/* Our Vision Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-crosshairs" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Vision</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Vision</h4>
//                   <p style={{ color: 'white', fontSize: '13px' }}>
//                     Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Mission Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-bullseye" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Mission</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Mission</h4>
//                   <p style={{ color: 'white', fontSize: '12px' }}>
//                     our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape. </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Values Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                   <i className="fa fa-heart" style={{ color: '#363435' }}></i>

//                   </div>

//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Values</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Values</h4>
//                   <p style={{ color: 'white', fontSize: '14px' }}>
//                     Innovation<br />
//                     Integrity<br />
//                     Collaboration<br />
//                     Customer-Centricity<br />
//                     Excellence<br />
//                     Sustainability<br />
//                     Adaptability
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </section>





//   );
// };

// export default OurCommitments;




import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import officeColleagueOne from '../img/officeColleagueOne.jpg';
 import './OurCommitments.css'
import MissionValuesVisionCombined from '../img/banner/values.jpg';
// import OurMissionFreepik from '../img/OurMissionFreepik.png';
import OurValuesHandPik from '../img/banner/Vision.png';
import ourVisionCardRed1 from '../img/banner/Mission.png';
import ourMissionCardRed2 from '../img/banner/values.jpg';

const OurCommitments = () => {
  return (
    <div style={{  }} className="our-commitments">
      <div className="text-center">
      <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
                       Our <span style={{ color: "#3395AD" }}>Commitments</span>
                       <span style={{
                         position: 'absolute',
                         bottom: '-8px',
                         left: '50%',
                         transform: 'translateX(-50%)',
                         width: '50%',
                         height: '2px',
                         backgroundColor: '#000'
                       }} />
                     </h1>
      </div>
      <div className="wrapper">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={OurValuesHandPik} alt="Our Vision" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                <h1>Our Vision</h1>
                <p style={{ fontSize: '14px' }}> Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.</p>
                
              </div>
            </div>
          </div>

          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={ourVisionCardRed1} alt="Our Mission" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                <h1>Our Mission</h1>
                <p>our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape.</p>
               
              </div>
            </div>
          </div>

          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={ourMissionCardRed2} alt="Our Values" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                  <h1>Our Values</h1>
                   <p>Innovation
                     Integrity
                     Collaboration
                     Customer-Centricity
                     Excellence
                     Sustainability<br />
                     Adaptability</p>
               
              </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default OurCommitments;

// import React from 'react';

// const OurCommitments = () => {
//   return (
//     <section id="commitment" style={{ paddingTop: '40px' }}>
//       <div className="container">
//         <div className="row">
//           <section id="cards-containers" style={{ textAlign: 'center' }}>
//             <div className="container">
//               <h1
//                 style={{
//                   color: "#000",
//                   fontSize: "48px",
//                   display: "inline-block",
//                   position: "relative",
//                   fontFamily: "lato,sans,serif",
//                 }}
//               >
//                 Our <span style={{ color: "#3395AD" }}>Commitments</span>
//                 <span
//                   style={{
//                     position: "absolute",
//                     bottom: "-8px",
//                     left: "50%",
//                     transform: "translateX(-50%)",
//                     width: "50%",
//                     height: "2px",
//                     backgroundColor: "#000",
//                   }}
//                 />
//               </h1>
//             </div>

//             {/* Cards */}
//             <div className="cards-wrapper">
//               {/* Our Vision Card */}
//               <div className="cards">
//                 <div className="card-tops">
//                   <div className="icon">
//                     <i className="fa fa-crosshairs"></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4>Our Vision</h4>
//                   <p>
//                     Our vision is to be the premier provider of integrated
//                     technology solutions, driving innovation and transformation
//                     for businesses worldwide.
//                   </p>
//                 </div>
//               </div>

//               {/* Our Mission Card */}
//               <div className="cards">
//                 <div className="card-tops">
//                   <div className="icon">
//                     <i className="fa fa-bullseye"></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4>Our Mission</h4>
//                   <p>
//                     Our mission is to empower organizations by delivering
//                     customized, cutting-edge technology solutions that drive
//                     growth, enhance efficiency, and foster innovation.
//                   </p>
//                 </div>
//               </div>

//               {/* Our Values Card */}
//               <div className="cards">
//                 <div className="card-tops">
//                   <div className="icon">
//                     <i className="fa fa-heart"></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4>Our Values</h4>
//                   <p>
//                     Innovation, Integrity, Collaboration, Customer-Centricity,
//                     Excellence, Sustainability, Adaptability.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>

//       {/* CSS */}
//       <style jsx>{`
//         .cards-wrapper {
//           display: flex;
//           justify-content: center;
//           gap: 20px;
//           flex-wrap: wrap;
//         }

//         .cards {
//           width: 250px;
//           height: 400px;
//           background: linear-gradient(135deg, #8ae5a1, #3395ad);
//           border-radious:150px 0px 150px 0px;
         

//           box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
//           overflow: hidden;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: flex-start;
//           transition: transform 0.3s, box-shadow 0.3s;
//         }

//         .cards:hover {
//           transform: scale(1.05);
//           box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
//         }

//         .card-tops {
//           width: 100%;
//           height: 150px;
//           background-color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//         }

//         .icon {
//           font-size: 48px;
//           color: #363435;
//         }

//         .card-content {
//           text-align: center;
//           padding: 20px;
//         }

//         .card-content h4 {
//           margin-bottom: 10px;
//           font-size: 20px;
//           color: #fff;
//         }

//         .card-content p {
//           font-size: 14px;
//           color: #e0e0e0;
//           line-height: 1.6;
//         }
//       `}</style>
//     </section>
//   );
// };

// export default OurCommitments;

