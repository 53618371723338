import React from 'react';
import Slider from "react-slick";
import iimg2 from '../img/projects/img1.jpg';
import iimg3 from '../img/projects/img2.jpg';
import iimg4 from '../img/projects/img3.jpg';
import iimg1 from '../img/projects/img4.jpg';
import iimg5 from '../img/projects/project/project-2.jpg';
import iimg6 from '../img/projects/project/project-10.jpg';
// import Commercial7 from '../img/banner/Commercial7.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Services = () => {
  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
     autoplay: true,
      autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <section className="services-boxes-area" style={{ backgroundColor: '#e6eeef' }}>
      <div className="">
        <Slider {...settings}>
          <div className="single-box">
            <img src={iimg2} alt="Residential Interiors" />
            <div className="contents">
              <h3>
                <Link to="/it-services">Residential Interiors </Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/it-services">Residential Interiors</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> Enhance your home with tailored interiors that seamlessly combine comfort and sophistication.</p>
              <Link to="/it-services" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg3} alt="Commercial Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-mobile-applications">Commercial Interiors</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-mobile-applications">Commercial Interiors</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span>  Create inspiring workspaces with commercial interiors that balance modern design and functionality</p>
              <Link to="/web-mobile-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg4} alt="Hospitality Interiors" />
            <div className="contents">
              <h3>
                <Link to="/gaming">Hospitality Interiors</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/gaming">Hospitality Interiors</Link>
              </h3>
              <p>Craft unforgettable guest experiences with hospitality interiors that exude warmth and elegance</p>
              <Link to="/gaming" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg1} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/snowcsc">Showcase Interiors</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/snowcsc">Showcase Interiors</Link>
              </h3>
              <p>Present your products in style with showcase interiors that captivate and highlight every detail <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> </p>
              <Link to="/snowcsc" className="read-more-btn">Learn More</Link>
            </div>
          </div>



          <div className="single-box">
            <img src={iimg5} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/Specialty">Specialty Interiors</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/Specialty">Specialty Interiors</Link>
              </h3>
              <p>Design unique spaces with specialty interiors that cater to specific needs and extraordinary aesthetics <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span></p>
              <Link to="/Specialty" className="read-more-btn">Learn More</Link>
            </div>
          </div>



          <div className="single-box">
            <img src={iimg6} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/Outdoor">Outdoor Environments</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/Outdoor">outdoor Environments</Link>
              </h3>
              <p>Transform your exterior spaces with outdoor environments that blend natural beauty with innovative design <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span></p>
              <Link to="/Outdoor" className="read-more-btn">Learn More</Link>
            </div>
          </div>

         
        </Slider>
      </div>
    </section>
  );
};

export default Services;
