


import React from "react";
import Slider from "react-slick";

// Import images
import picture1 from "../img/banner/logo10.png";
import asm from "../img/banner/Picture2.png";
import group from "../img/banner/Picture1.jpg";

const Ourclints = () => {
  const logoSliderss = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    dots: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container-fluid" style={{ paddingBottom: "20px",backgroundColor:'#fff',height:'220px'  }}>
        {/* <div className="container"> */}
          <div className="row text-center">
            {/* Centered Heading */}
            <div className="col-12">
            <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
                       Our <span style={{ color: "#3395AD" }}>Client</span>
                       <span style={{
                         position: 'absolute',
                         bottom: '-8px',
                         left: '50%',
                         transform: 'translateX(-50%)',
                         width: '50%',
                         height: '2px',
                         backgroundColor: '#000'
                       }} />
                     </h1>
            </div>
          </div>

         {/* Image Slider Section */}
<div className="row">
  <div className="col-12">
    <Slider {...logoSliderss}>
      {/* Image 1 */}
      <div className="single-partner" style={{ padding: "0 5px" }}>
        <img
          src={picture1}
          alt="Client Logo 1"
          style={{
            height: "65px",
           
            margin: "0 auto", // Center image
            display: "block",
            objectFit: "contain", // Ensure the image fits within the bounds
          }}
        />
      </div>

      {/* Image 2 */}
      <div className="single-partner" style={{ padding: "" }}>
        <img
          src={asm}
          alt="Client Logo 2"
          style={{
            height: "65px",
           
            margin: "0 auto", // Center image
            display: "block",
            objectFit: "contain", // Ensure the image fits within the bounds
          }}
        />
      </div>

      {/* Image 3 */}
      <div className="single-partner" style={{ padding: "" }}>
        <img
          src={group}
          alt="Client Logo 3"
          style={{
            height: "65px",
           
            margin: "0 auto", // Center image
            display: "block",
            objectFit: "contain", // Ensure the image fits within the bounds
          }}
        />
      </div>
    </Slider>
  </div>
</div>

              
            </div>
          </div>
       
      
   
  );
};

export default Ourclints;





